<template lang="pug">
.onboarding-dashboard-section(v-if="!$apollo.loading && visible")
  .onboarding-dashboard-section-close(v-if="allDone")
    close-icon.cursor-pointer(@click.native="hide" :width="12" :height="12" color="#505763")
  om-heading.onboarding-dashboard-section-title(h3) {{ $tc(`dashboardSections.onboarding.${allDone ? 'doneTitle' : 'title'}`, countStepLeft, { count: countStepLeft }) }}
  .row
    .col
      SectionCard(
        v-for="task in tasks"
        :key="`section-card-${task}`"
        :icon="`dashboard-section-${task}`"
        :translationsPrefix="`dashboardSections.onboarding.steps.${task}`"
        :done="isTaskDone(task)"
        @click="onTaskClick(task)"
      )
    .col-0
      .support-section
        .row
          .col.left-section
            .support-section-title.ml-2 {{ $t('dashboardSections.onboarding.supportSection.title') }}
            .support-section-text.ml-2 {{ $t('dashboardSections.onboarding.supportSection.text') }}
            om-button.support-section-action.mb-2(
              primary
              ghost
              small
              icon="comments"
              v-if="hasIntercom"
              @click="startChat"
            )
              span {{ $t('dashboardSections.onboarding.supportSection.startChat') }}
            om-button.support-section-action(
              primary
              ghost
              small
              icon="book-alt"
              @click="openKnowledgeBase"
            )
              span {{ $t('dashboardSections.onboarding.supportSection.visitKnowledgeBase') }}
          .col.right-section
            img.reka(:src="require('@/assets/admin/img/onboarding/dashboard/reka.png')")
            .full-name {{ $t('dashboardSections.onboarding.supportSection.reka') }}
            .position {{ $t('dashboardSections.onboarding.supportSection.customerSupportTeamLead') }}
  .row(v-if="showVip")
    .col
      .separator
      .vip-box
        .relative-box
          transition-group(name="fade")
            .confirmed(v-if="vipRequested" key="1")
              .d-flex.align-items-center.mb-1
                UilCheckCircle.check.mr-2
                om-heading(h5 v-html="$t('onboarding.wizard.choosePath.expert.confirmTitle')")
              om-body-text.gray-700(bt400md) {{ $t('onboarding.wizard.choosePath.expert.confirmDescription') }}
            .request(v-else key="2")
              span.pr-5
                om-heading(h3) {{ $t('onboarding.wizard.choosePath.expert.title') }}
                om-body-text.mt-2.gray-700(bt400md) {{ $t('onboarding.wizard.choosePath.expert.description') }}
              span
                om-button(primary large @click="requestVIP") {{ $t('onboarding.wizard.choosePath.expert.button') }}
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { get } from 'lodash-es';
  import moment from 'moment';
  import { track } from '@/services/xray';
  import { PROFILE_KEY_ONBOARDING_DONE } from '@/config/constants';
  import vipOnboarding from '@/mixins/vipOnboarding';
  import { UilCheckCircle } from '@iconscout/vue-unicons';
  import dashboardTaskListMixin from '@/mixins/dashboardTaskList';

  const PROFILE_KEY_ACTIVATED = 'onboardingSectionActivated';
  const PROFILE_KEY_SHOWN = 'onboardingSectionShown';

  const HEAP_EVENT_NAMES = {
    connect: 'insertCode',
    create: 'createFirstCampaign',
    activate: 'activateFirstCampaign',
    close: 'close',
  };

  const tasks = ['create', 'connect', 'activate'];

  export default {
    components: {
      YouTubeVideo: () => import('@/components/YouTubeVideo.vue'),
      SectionCard: () => import('./DashboardSectionCard.vue'),
      UilCheckCircle,
    },
    mixins: [vipOnboarding, dashboardTaskListMixin],
    props: {
      inactiveCampaignId: { type: Number, default: 0 },
      campaignCount: { type: Number, default: 0 },
    },
    data: () => ({
      visible: false,
      tasks,
    }),
    computed: {
      ...mapState(['account']),
      ...mapGetters(['userProfileDoneValue', 'isTaskListCompleted', 'getLocale']),

      allDone() {
        const validate =
          this.isTaskDone('connect') && this.isTaskDone('create') && this.isTaskDone('activate');
        return this.isAlreadyDone || validate;
      },
      isShownBefore() {
        return !!get(this.account, `profile.${PROFILE_KEY_SHOWN}`, false);
      },
      isAlreadyDone() {
        return this.isTaskListCompleted;
      },
      isActivatedBefore() {
        return !!get(this.account, `profile.${PROFILE_KEY_ACTIVATED}`, false);
      },
      countStepLeft() {
        const completed = this.tasks.reduce((acc, current) => {
          acc += this.isTaskDone(current) ? 1 : 0;
          return acc;
        }, 0);
        return 3 - completed;
      },

      showVip() {
        return this.account.profile?.isQualifiedLead && !this.account.profile?.vipOnboarding;
      },
      hasIntercom() {
        return !!window.Intercom;
      },
    },
    async created() {
      await this.setShopifyAppEmbedStatuses();
      await this.setImpressionFlag();

      const isOldUserDone = !this.isShownBefore && this.allDone;

      if (isOldUserDone) {
        await this.setToUserProfile(PROFILE_KEY_ONBOARDING_DONE, true);
        return;
      }

      if (!this.isAlreadyDone) {
        await this.setToUserProfile(PROFILE_KEY_SHOWN, true);

        if (this.allDone && !this.userProfileDoneValue) {
          await this.setToUserProfile(
            PROFILE_KEY_ONBOARDING_DONE,
            moment().utc().add(24, 'hours').toISOString(),
          );
        }
      }

      this.visible = !this.isAlreadyDone;
    },
    methods: {
      connect() {
        this.$router.push({ name: 'code-insert' });
      },
      create() {
        this.$router.push({ name: 'templates' });
      },
      activate() {
        if (!this.campaignCount) return this.create();
        if (this.campaignCount === 1) {
          this.$router.push({ name: 'campaign_variants', params: { id: this.inactiveCampaignId } });
          return;
        }
        this.$router.push({ name: 'campaigns' });
      },
      getEventName(task) {
        const eventName = HEAP_EVENT_NAMES[task];
        return `dashboard-onboarding-${eventName}`;
      },
      onTaskClick(task) {
        if (this.isTaskDone(task)) return;
        const eventName = this.getEventName(task);
        track(eventName);
        this?.[task]?.(task);
      },
      isTaskDone(task) {
        if (task === 'connect') {
          return this.isFullyConnected;
        }
        if (task === 'create') {
          return !!this.campaignCount;
        }
        if (task === 'activate') {
          return this.isActivatedBefore || this.hasConversion || this.hadImpressionEver;
        }
      },
      async hide() {
        this.visible = false;
        const eventName = this.getEventName('close');
        track(eventName);
        await this.setToUserProfile(PROFILE_KEY_ONBOARDING_DONE, true);
      },
      startChat() {
        window.Intercom('show');
      },
      openKnowledgeBase() {
        const huLink = 'https://support.optimonk.hu/hc/hu';
        const enLink = 'https://support.optimonk.com/hc/en-us';
        const linkByLang = this.getLocale === 'en' ? enLink : huLink;
        return window.open(linkByLang, '_blank');
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .support-section-action
    padding-left: 0.375rem !important
    padding-right: 0.375rem !important
  .brand-svg-transition-bg-icon
    z-index: 0
  .onboarding-dashboard-section
    margin-top: 2rem
    padding: 1.5rem
    border-radius: .5rem
    background-color: #FFF7F2
    position: relative
    &-close
      position: absolute
      top: 1.5rem
      right: 1.5rem
      height: .75rem
      line-height: 1
      span
        line-height: 1
        height: .75rem
    &-title
      margin-bottom: 1.5rem
    .yt-video
      width: 100%
      height: 16.5rem
      border-radius: .5rem
    .vip-box
      background-color: $om-peach-200
      border-radius: 8px
      padding: 16px 24px
      display: flex
      .request
        display: flex
        align-items: center
        justify-content: space-between
        position: absolute
        left: 0
        top: 0
        bottom: 0
        width: 100%
      .confirmed
        display: flex
        justify-content: center
        flex-direction: column
        position: absolute
        left: 0
        top: 0
        bottom: 0
        width: 100%
      .relative-box
        position: relative
        width: 100%
        height: 82px
      .check
        width: 48px
        height: 48px
        color: $om-alert-green-600
    .separator
      height: 0
      margin: 24px 0
      border-top: 1px solid $om-peach-400
    .support-section
      display: flex
      margin-left: 40px
      margin-right: 24px
      width: 465px
      height: 100%
      border-radius: 8px
      padding: 16px 20px
      background: url('~@/assets/admin/svg/onboarding/support-section-background.svg')
      background-size: cover
      background-position: top
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04), 0px 4px 10px 0px rgba(0, 0, 0, 0.10)
      .left-section
        min-width: 240px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
      &-title
        color: $om-gray-800
        font-size: 24px
        font-weight: 700
        line-height: 1.3
        margin-bottom: 12px
      &-text
        color: $om-gray-700
        font-size: 18px
        line-height: 1.5
        margin-bottom: 8px
      .right-section
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        margin-right: -30px
        .reka
          width: 172px
          height: 172px
        .full-name
          margin-top: -40px
          padding: 5px 10px
          background: #8444E1
          color: #FFF
          font-size: 18px
          font-weight: 700
        .position
          width: 151px
          padding: 5px 10px
          color: $om-gray-700
          background: $om-gray-300
          font-size: 16px
          text-align: center
</style>
